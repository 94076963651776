<footer class="footer"
        [ngStyle]="{
          'background-color': backgroundColor
        }">
  <a href="https://higherjobs.uhigher.com/" class="footer-content">
    Hire with
    <img class="logo"
         alt="Higher company logo"
         src="../../../../assets/images/higher-logo.svg" />
  </a>
</footer>
